export default [
  {
    key: 'dealerCompanyName',
    label: 'field.dealerCompanyName',
    rules: 'required',
    rules: 'required',
    type: 'text',
    disabled: true,
  },
  {
    key: 'vehicleName',
    label: 'field.vehicle',
    rules: 'required',
    rules: 'required',
    type: 'text',
    disabled: true,
  },
  {
    key: 'soldPrice',
    label: 'field.finalPrice',
    rules: 'required',
    type: 'currency',
    prefix: '$',
    disabled: true,
  },
  {
    key: 'revisedPrice',
    label: 'field.revisedPrice',
    rules: 'required',
    type: 'currency',
    prefix: '$',
    disabled: true,
  },
  {
    key: 'price',
    label: 'field.balance',
    rules: 'required',
    type: 'currency',
    prefix: '$',
    disabled: true,
  },
  {
    key: 'amount',
    label: 'field.payAmount',
    rules: 'required|decimal:2',
    type: 'currency',
    prefix: '$',
    disabled: true,
  },
  {
    key: 'paymentType',
    label: 'field.paymentMethod',
    rules: 'required',
    type: 'single-selection',
    rules: 'required',
    selectionKey: 'value',
    selectionLabel: 'text',
    translatable: true,
    options: [
      { text: 'field.cash', value: 'cash' },
      { text: 'field.bank', value: 'bank' },
    ],
    disabled: true,
  },
  {
    key: 'payDate',
    label: 'field.payDate',
    rules: 'required',
    type: 'date-picker',
    disableType: 'after',
    disabled: true,
  },
  {
    key: 'reference',
    label: 'field.referenceNo',
    rules: '',
    type: 'text',
    disableType: 'after',
    disabled: true,
  },
  {
    key: 'documentId',
    label: 'field.document',
    rules: '',
    fileKey: 'document',
    type: 'async-file-uploader',
    disabled: true,
  },
  {
    key: 'remark',
    label: 'field.remark',
    rules: 'max:200',
    type: 'textarea',
    cols: 12,
  },
]
