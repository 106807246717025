<template>
  <b-modal
    id="document-modal"
    centered
    :title="$t('field.document')"
    size="xl"
    hide-footer
  >
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-row class="image-items">
          <b-col
            cols="6"
            md="4"
            lg="3"
            xl="2"
            v-for="(item, i) in items"
            :key="`document-${i}`"
            :class="{
              'p-0': !item.documentId,
            }"
            class="image-item pb-2"
          >
            <b-card no-body class="mb-0">
              <b-skeleton-img></b-skeleton-img>

              <b-skeleton width="70%" class="mt-1"></b-skeleton>
            </b-card>
          </b-col>
        </b-row>
      </template>
      <div>
        <b-row class="image-items">
          <b-col
            cols="6"
            md="4"
            lg="3"
            xl="2"
            v-for="(item, i) in items"
            :key="`document-${i}`"
            :class="{
              'p-0': !item.documentId,
            }"
            class="image-item pb-2"
          >
            <div
              class="text-center cursor-pointer"
              @click="viewFile(item)"
              v-if="item.documentId"
            >
              <b-img
                class="document-thumbnail"
                fluid
                :src="item.fileUrl"
                v-if="item.fileType.startsWith('image')"
              />
              <b-img
                class="document-thumbnail"
                fluid
                :src="require('@/assets/images/icons/pdf.png')"
                v-else
              />
            </div>
            <div class="text-center">
              {{ item.fileName }}
            </div>
          </b-col>
        </b-row>
        <b-button variant="primary" block @click="chooseFile">
          <b-row>
            <b-col class="text-left"> {{ $t("button.uploadFile") }}</b-col>
            <b-col cols="auto" class="text-right">
              <feather-icon icon="PlusIcon" class="text-right" />
            </b-col>
          </b-row>
        </b-button>
      </div>
    </b-skeleton-wrapper>
    <input
      type="file"
      style="display: none"
      multiple
      name="fields[assetsFieldHandle][]"
      id="assetsFieldHandle"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      @change="onChange"
      ref="fileInput"
      accept=".pdf,.jpg,.jpeg,.png"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BCard,
  BImg,
  BRow,
  BCol,
  BButton,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonImg,
} from "bootstrap-vue";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NTable from "@/components/NTable";
import NPagination from "@/components/NPagination";

const PaymentDocumentRepository = Repository.get("paymentDocument");
const FileRepository = Repository.get("file");

export default {
  components: {
    BModal,
    BCard,
    BImg,
    BRow,
    BCol,
    BButton,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonImg,

    NAsyncSingleSelect,
    NTable,
    NPagination,
    NInput,
  },
  data() {
    return {
      key: 1,
      loading: false,
      paymentId: null,
      items: [],
    };
  },
  mounted() {},
  methods: {
    show(item) {
      this.items = [];
      this.paymentId = item.id;
      this.getData();
      this.$bvModal.show("document-modal");
    },
    hide() {
      this.$bvModal.hide("document-modal");
    },
    viewFile(item) {
      if (item.fileType.startsWith("image/")) {
        this.$imagePreview({
          initIndex: 0,
          images: [item.fileUrl],
          zIndex: 9999,
          isEnableDownloadImage: false,
          isEnableImagePageIndicator: false,
          isEnableBlurBackground: true,
          onClose: () => {},
        });
      } else {
        window.open(item.fileUrl, "_blank").focus();
      }
    },
    getData() {
      this.loading = true;
      PaymentDocumentRepository.index(this.paymentId)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data];
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    chooseFile() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    onChange() {
      this.filelist = [...this.$refs.fileInput.files];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.uploadFile(this.filelist[0]);
      };
      reader.readAsDataURL(this.filelist[0]);
    },
    async uploadFile(file) {
      this.loading = true;
      let formData = new FormData();
      formData.append("file", file);
      FileRepository.uploadStream(formData)
        .then((response) => {
          const res = response?.data?.data;
          if (res) {
            this.upload(res.mediaId);
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    async upload(mediaId) {
      this.loading = true;
      PaymentDocumentRepository.upload({
        paymentId: this.paymentId,
        documentId: mediaId,
      })
        .then((response) => {
          const res = response?.data?.data;
          if (res) {
            this.getData();
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
  setup() {
    const resource = "payment";
    const route = "payment";

    return {
      resource,
      route,
    };
  },
};
</script>