<template>
  <div>
    <b-modal
      centered
      :title="`${$t('breadcrumb.paymentTransaction')} - ${title}`"
      size="xl"
      hide-footer
      v-model="isShow"
    >
      <div>
        <n-table
          ref="table"
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :updatable="false"
          :deletable="false"
          :resource="resource"
          :route="route"
          :loading="loading"
        >
          <template #cell(createdBy)="data">
            <span v-if="data.item.createdBy">
              {{ data.item.createdBy.name }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(payAmount)="data">
            {{ data.item.payAmount | currency }}
          </template>
          <template #cell(dealer)="data">
            <span v-if="data.item.dealer">
              {{ data.item.dealer.name }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.paymentStatus == 1"
            >
              {{ $t(`field.verified`) }}
            </b-badge>
            <b-badge pill variant="warning" badge-glow v-else>
              {{ $t(`field.review`) }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-b-tooltip.hover
                :title="$t('button.update')"
                pill
                size="sm"
                :variant="
                  data.item.paymentStatus != 0 ? 'secondary' : 'primary'
                "
                :disabled="data.item.paymentStatus != 0"
                @click="openUpdateForm(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$t('button.verify')"
                variant="success"
                pill
                size="sm"
                v-if="data.item.paymentStatus == 0"
                @click="openConfirmForm(data.item)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$t('button.print')"
                variant="primary"
                pill
                size="sm"
                v-if="data.item.paymentStatus == 1"
                @click="viewReciept(data.item)"
              >
                <feather-icon icon="PrinterIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-modal>
    <update-payment-modal
      @success="updatePaymentSuccess"
      ref="updatePaymentModal"
    />
    <verify-payment-modal
      @success="verifyPaymentSuccess"
      ref="verifyPaymentModal"
    />
  </div>
</template>

<script>
import { BModal, BBadge, BButton } from "bootstrap-vue";
import TableFields from "./tableFields";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NTable from "@/components/NTable";
import NPagination from "@/components/NPagination";
import UpdatePaymentModal from "../updatePaymentModal/Index.vue";
import VerifyPaymentModal from "../verifyPaymentModal/Index.vue";

const PaymentHistoryRepository = Repository.get("paymentHistory");

export default {
  components: {
    BModal,
    BBadge,
    BButton,

    NAsyncSingleSelect,
    NTable,
    NPagination,
    NInput,
    UpdatePaymentModal,
    VerifyPaymentModal,
  },
  data() {
    return {
      key: 1,
      isShow: false,
      loading: false,
      items: [],
      item: {},
      total: 0,
      id: null,
      title: "",
      invoiceNo: "",
      params: {},
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
      },
    };
  },
  mounted() {},
  methods: {
    updatePaymentSuccess() {
      this.getData();
      this.$emit("confirm");
    },
    verifyPaymentSuccess() {
      this.getData();
      this.$emit("confirm");
    },
    openUpdateForm(item) {
      this.$refs.updatePaymentModal.show(item, this.item);
    },
    openConfirmForm(item) {
      this.$refs.verifyPaymentModal.show(item, this.item);
    },
    show(item) {
      this.title = `${item.year ? item.year : ""} ${
        item.brandName ? item.brandName : ""
      } ${item.model ? item.model : ""} ${
        item.engineCapacity ? item.engineCapacity : ""
      } ${item.transmission ? item.transmission : ""}`;
      this.item = {
        ...item,
      };
      this.items = [];
      this.total = 0;
      this.query.page = 1;
      this.getData();
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    list(page = 1) {
      this.query.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: this.searchFields,
        query: [
          {
            searchby: "payment_id",
            searchoperator: "=",
            search: this.item.id,
          },
        ],
      };
      PaymentHistoryRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    viewReciept(item) {
      this.loading = true;
      PaymentHistoryRepository.exportReciept(item.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            window.open(data.fileUrl, "_blank").focus();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...TableFields];
    const resource = "payment";
    const route = "payment";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>