export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
    hideToggle: true,
  },
  {
    key: 'createdAt',
    label: 'field.createdDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'type',
    label: 'field.action',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'amount',
    label: 'field.amount',
  },
  {
    key: 'document',
    label: 'field.document',
  },
  {
    key: 'remark',
    label: 'field.remark',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
];
