<template>
  <div>
    <load-profile></load-profile>
    <n-search-container @search="search" @reset="reset" :fields="searchFields" :loading="loading">
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          </b-col>
          <b-col cols="12" md="auto" class="text-right mt-1 mt-md-0">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <b-button variant="success" @click="exportExcel">
                  {{ $t("button.export") }}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table ref="table" :key="`table-${key}`" @sort-changed="sortChanged" @select="select" :fields="fields"
          :items="items" :busy="loading" :current-page="params.page" :per-page="$store.state.pagination.perPage"
          :total="total" :updatable="false" :deletable="false" :resource="resource" :route="route" :is-select="isSelect"
          :selected="selected">
          <template #head(increasement)>
            <div class="text-nowrap cursor-pointer" @click="isSelect = !isSelect">
              <feather-icon icon="CheckSquareIcon" v-if="!isSelect"></feather-icon>
              <feather-icon icon="XIcon" v-else></feather-icon>
            </div>
          </template>
          <template #cell(bidDate)="data">
            <span v-if="data.item.bidDate">
              {{ data.item.bidDate | formatDate("DD-MMMM-YYYY") }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(paymentType)="data">
            <span v-if="data.item.paymentType">
              {{ $t(`field.${data.item.paymentType}`) }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(soldPrice)="data">
            <span v-if="data.item.soldPrice">
              {{ data.item.soldPrice | currency }}
            </span>
            <span v-else>0</span>
          </template>
          <template #cell(adjustAmount)="data">
            <b-link class="link-underline" :class="{
              'text-success': data.item.adjustAmount > 0,
              'text-danger': data.item.adjustAmount < 0,
            }" v-if="data.item.adjustAmount" @click="openTopupDiscountModal(data.item)">
              {{ data.item.adjustAmount | currency }}
            </b-link>
            <span v-else>0</span>
          </template>
          <template #cell(payAmount)="data">
            <span v-if="data.item.payAmount">
              {{ data.item.payAmount | currency }}
            </span>
            <span v-else>0</span>
          </template>
          <template #cell(remainAmount)="data">
            <span v-if="data.item.remainAmount">
              {{ data.item.remainAmount | currency }}
            </span>
            <span v-else>0</span>
          </template>
          <template #cell(revisedPrice)="data">
            <span v-if="data.item.revisedPrice">
              {{ data.item.revisedPrice | currency }}
            </span>
            <span v-else>0</span>
          </template>
          <template #cell(payDate)="data">
            <span v-if="data.item.payDate">
              {{ data.item.payDate | formatDate("DD-MMMM-YYYY") }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(vinNumber)="data">
            <span v-if="data.item.vinNumber">
              {{ data.item.vinNumber }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(dealer)="data">
            <span v-if="data.item.dealer">
              {{ data.item.dealer.name }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(bdPic)="data">
            <span v-if="data.item.bdPic">
              {{ data.item.bdPic.name }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(status)="data">
            <span :class="getTextClassByStatus(data.item.status)">
              {{ $t(`status.${data.item.status}`) }}
            </span>
          </template>
          <template #cell(verified)="data">
            <span v-if="data.item.verified == null">-</span>
            <b-link class="link-underline" :class="{
              'text-success': data.item.verified,
              'text-danger': !data.item.verified,
            }" @click="viewPayment(data.item)" v-else>
              {{ data.item.verified ? $t("field.yes") : $t("field.no") }}
            </b-link>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button v-b-tooltip.hover :title="$t('button.payment')" pill size="sm"
                @click="openPaymentModal(data.item)" :disabled="![
                  vehicleState.PENDING_PAYMENT,
                  vehicleState.PARTIAL_PAID,
                ].includes(Number(data.item.status))
                  " :variant="![
                    vehicleState.PENDING_PAYMENT,
                    vehicleState.PARTIAL_PAID,
                  ].includes(Number(data.item.status))
                    ? 'secondary'
                    : 'success'
                    ">
                <feather-icon icon="DollarSignIcon" />
              </b-button>
              <b-button v-b-tooltip.hover :title="$t('button.invoice')" variant="primary" pill size="sm"
                @click="viewInvoice(data.item)">
                <feather-icon icon="FileIcon" />
              </b-button>
              <b-button v-b-tooltip.hover :title="$t('button.documents')" variant="primary" pill size="sm"
                @click="viewDocument(data.item)">
                <feather-icon icon="FolderIcon" />
              </b-button>
              <b-button v-b-tooltip.hover :title="$t('button.remark')" variant="primary" pill size="sm"
                @click="openRemark(data.item)">
                <feather-icon icon="MessageCircleIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination ref="pagination" :total="total" :per-page="$store.state.pagination.perPage" :page="params.page"
          @change="list"></n-pagination>
      </div>
    </b-card>

    <create-payment-modal @success="getData" ref="createPaymentModal" />
    <discount-modal @success="getData" ref="discountModal" />
    <topup-modal @success="getData" ref="topupModal" />
    <topup-discount-transaction-modal ref="topupDiscountTransactionModal" />
    <payment-transaction-modal @confirm="getData" ref="paymentTransactionModal" />
    <document-modal ref="documentModal" />
    <remark-modal ref="remarkModal" />
    <payment-modal ref="paymentModal" @create="makePayment" @discount="showDiscountModal" @topup="showTopupModal" />
  </div>
</template>

<script>
  import {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BLink,
    BFormGroup,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import Repository from "@/repositories/RepositoryFactory";
  import NPagination from "@/components/NPagination";
  import NSearchContainer from "@/components/NSearchContainer";
  import NSearchInput from "@/components/NSearchInput";
  import NTable from "@/components/NTable";
  import TableFields from "./tableFields";
  import SearchInputs from "./searchInput";
  import { getTextClassByStatus, mapExportField } from "@/libs/helper";
  import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
  import NColumnVisibility from "@/components/NColumnVisibility";
  import CreatePaymentModal from "./createPaymentModal/Index.vue";
  import PaymentTransactionModal from "./paymentTransactionModal/Index.vue";
  import DocumentModal from "./documentModal/Index.vue";
  import RemarkModal from "./remark/RemarkModal.vue";
  import PaymentModal from "./paymentModal/Index.vue";
  import DiscountModal from "./discountModal/Index.vue";
  import TopupModal from "./topupModal/Index.vue";
  import TopupDiscountTransactionModal from "./topupDiscountTransactionModal/Index.vue";
  import FileSaver from "file-saver";
  import moment from "moment";

  const PaymentRepository = Repository.get("payment");

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BButton,
      BBadge,
      BLink,
      BFormGroup,

      NPagination,
      NSearchContainer,
      NSearchInput,
      NAsyncSingleSelect,
      NTable,
      NColumnVisibility,
      vSelect,

      CreatePaymentModal,
      PaymentTransactionModal,
      DocumentModal,
      RemarkModal,
      PaymentModal,
      DiscountModal,
      TopupModal,
      TopupDiscountTransactionModal,
    },
    watch: {
      perPage() {
        this.list();
      },
      isSelect(value) {
        if (!value) {
          this.selected = [];
        }
      },
    },
    computed: {
      perPage: {
        get() {
          return this.$store.state.pagination.perPage;
        },
        set(value) {
          this.$store.commit("pagination/SET_PER_PAGE", value);
        },
      },
    },
    data() {
      return {
        key: 1,
        isSelect: false,
        selected: [],
        items: [],
        loading: false,
        total: 0,
        perPageOptions: this.$store.state.pagination.perPageOptions,
        params: {
          bidDate: this.$route.query.bidDate || [],
          vehicle: this.$route.query.vehicle || [],
          vinNumber: this.$route.query.vinNumber || [],
          bd: this.$route.query.bd || [],
          userId: this.$route.query.userId || [],
          payDate: this.$route.query.payDate || [],
          latestPaymentType: this.$route.query.latestPaymentType || [],
          status: this.$route.query.status || [],
          verified: this.$route.query.verified || [],
          locationId: this.$route.query.locationId || [],
        },
        query: {
          page: Number(this.$route.query.page) || 1,
          order: this.$route.query.order || null,
          sort: this.$route.query.sort || null,
          bidDate: this.$route.query.bidDate || [],
          vehicle: this.$route.query.vehicle || [],
          vinNumber: this.$route.query.vinNumber || [],
          bd: this.$route.query.bd || [],
          userId: this.$route.query.userId || [],
          payDate: this.$route.query.payDate || [],
          latestPaymentType: this.$route.query.latestPaymentType || [],
          status: this.$route.query.status || [],
          verified: this.$route.query.verified || [],
          locationId: this.$route.query.locationId || [],
        },
        getTextClassByStatus,
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      select(item) {
        const index = this.selected.findIndex((element) => {
          return element == item.id;
        });
        if (index == -1) {
          this.selected.push(item.id);
        } else {
          this.selected.splice(index, 1);
        }
      },
      openPaymentModal(item) {
        this.$refs.paymentModal.show(item);
      },
      makePayment(item) {
        this.$refs.createPaymentModal.show(item);
      },
      showDiscountModal(item) {
        this.$refs.discountModal.show(item);
      },
      showTopupModal(item) {
        this.$refs.topupModal.show(item);
      },
      openTopupDiscountModal(item) {
        this.$refs.topupDiscountTransactionModal.show(item);
      },
      openRemark(item) {
        this.$refs.remarkModal.show(item);
      },
      viewPayment(item) {
        this.$refs.paymentTransactionModal.show(item);
      },
      viewDocument(item) {
        this.$refs.documentModal.show(item);
      },
      updateQuerySting() {
        this.$router.replace({ query: this.query });
      },
      list(page = 1) {
        this.query.page = page;
        this.updateQuerySting();
        this.getData();
      },
      sortChanged(sortBy, sortDirection) {
        this.query.order = sortBy;
        this.query.sort = sortDirection;
        this.updateQuerySting();
        this.getData();
      },
      search() {
        this.query = {
          ...this.query,
          ...this.params,
        };
        this.query.page = 1;
        this.$refs.pagination.reset();
        this.updateQuerySting();
        this.getData();
      },
      reset() {
        this.$refs.search.reset();
        this.query.page = 1;
        this.$refs.pagination.reset();
        this.query = {
          ...this.query,
          ...this.params,
        };
        this.updateQuerySting();
        this.getData();
      },
      getData() {
        this.loading = true;
        const params = {
          ...this.query,
          searchFields: this.searchFields,
        };
        PaymentRepository.index(params)
          .then((response) => {
            const data = response?.data?.data;
            if (data) {
              this.items = [...data.list];
              this.total = data.total;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      exportExcel() {
        this.loading = true;
        let visibleColumns = [];
        this.fields.forEach((element) => {
          if (!element.hideToggle) {
            visibleColumns.push(element.key);
          }
        });
        let query = {
          ...this.query,
          searchFields: this.searchFields,
          fields: mapExportField(this.fields, visibleColumns, true),
        };
        if (this.selected.length > 0) {
          query = {
            query: [
              {
                searchby: "id",
                searchoperator: "in",
                search: this.selected,
              },
            ],
            fields: mapExportField(this.fields, visibleColumns),
          };
        }
        PaymentRepository.exportExcel(query)
          .then((response) => {
            this.loading = false;
            const fileName = `Payment - ${moment().format("DD-MMM-YYYY")}.xlsx`;
            FileSaver.saveAs(response.data, fileName);
          })
          .catch(() => {
            this.loading = false;
          });
      },
      viewInvoice(item) {
        this.loading = true;
        PaymentRepository.exportInvoice(item.id)
          .then((response) => {
            const data = response?.data?.data;
            if (data) {
              window.open(data.fileUrl, "_blank").focus();
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
    },
    setup() {
      const fields = TableFields;
      const searchFields = SearchInputs;
      const resource = "payment";
      const route = "payment";

      return {
        fields,
        searchFields,
        resource,
        route,
      };
    },
  };
</script>
