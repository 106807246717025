export default [
  {
    key: 'bidDate',
    label: 'field.auctionDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
    xl: 3,
  },
  {
    key: 'vehicle',
    label: 'field.lot',
    type: 'asyn-multi-selection',
    operator: 'in',
    searchField: 'vehicleId',
    repository: 'vehicle',
    selectionKey: 'id',
    selectionLabel: 'vehicleId',
    filterField: "vehicle_id",
    xl: 3,
  },
  {
    key: 'vinNumber',
    label: 'field.vinNumber',
    type: 'asyn-multi-selection',
    operator: 'in',
    searchField: 'vehicleId',
    repository: 'vehicle',
    selectionKey: 'id',
    selectionLabel: 'vinNumber',
    filterField: "vin_number",
    xl: 3,
  },
  {
    key: 'bd',
    label: 'field.bdPic',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'user',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
    xl: 3,
  },
  {
    key: 'userId',
    label: 'field.dealer',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'dealer',
    selectionKey: 'id',
    selectionLabel: 'companyName',
    clearable: true,
    xl: 3,
  },
  {
    key: 'payDate',
    label: 'field.payDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
    searchField: "latestPayDate",
    xl: 3,
  },
  {
    key: 'latestPaymentType',
    label: 'field.paymentMethod',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'field.cash', value: 'cash' },
      { text: 'field.bank', value: 'bank' },
    ],
    xl: 3,
  },
  {
    key: 'verified',
    label: 'field.verified',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'field.yes', value: '1' },
      { text: 'field.no', value: '0' },
    ],
    xl: 3,
  },
  {
    key: 'status',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'status.13', value: '13' },
      { text: 'status.14', value: '14' },
      { text: 'status.15', value: '15' },
      { text: 'status.16', value: '16' },
    ],
    xl: 3,
  },
  {
    key: 'locationId',
    label: 'field.location',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'location',
    selectionKey: 'id',
    selectionLabel: 'name',
    xl: 3,
  },
]