<template>
  <b-modal id="payment-modal" ok-variant="primary" cancel-variant="outline-secondary" :cancel-title="$t('button.close')"
    centered no-close-on-backdrop :title="$t('breadcrumb.createPayment')" @ok="handleOk" size="xl">
    <validation-observer ref="payment">
      <n-input :fields="formInput" v-model="data"> </n-input>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ $t("button.create") }}</span>
    </template>
  </b-modal>
</template>

<script>
  import { BModal, BSpinner, BFormGroup } from "bootstrap-vue";
  import FormInput from "./formInput";
  import NInput from "@/components/NInput";
  import Repository from "@/repositories/RepositoryFactory";
  import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
  import moment from "moment";

  const PaymentHistoryRepository = Repository.get("paymentHistory");

  export default {
    components: {
      BModal,
      BSpinner,
      BFormGroup,
      NAsyncSingleSelect,

      NInput,
    },
    data() {
      return {
        loading: false,
        data: {
          dealerName: null,
          vehicleName: null,
          paymentId: null,
          soldPrice: null,
          revisedPrice: null,
          price: null,
          paymentType: "cash",
          amount: null,
          payDate: moment().format("YYYY-MM-DD"),
          note: null,
          documentId: null,
          reference: null,
        },
        formInput: [...FormInput],
      };
    },
    watch: {
      "data.paymentType"(value) {
        if (value == "cash") {
          this.formInput.map((element) => {
            if (element.key == "reference" || element.key == "documentId") {
              element.rules = "";
            }

            return element;
          });
        } else {
          this.formInput.map((element) => {
            if (element.key == "reference" || element.key == "documentId") {
              element.rules = "required";
            }

            return element;
          });
        }
        this.key++;
      },
    },
    methods: {
      show(item) {
        this.formInput.map((element) => {
          if (element.key == "amount") {
            element.rules = `required|decimal:2|max_value:${item.remainAmount}`;
          }

          return element;
        });
        console.log({ item });

        this.data = {
          dealerName: item.dealerCompanyName,
          vehicleName: `${item.year ? item.year : ""} ${item.brandName ? item.brandName : ""
            } ${item.model ? item.model : ""} ${item.engineCapacity ? item.engineCapacity : ""
            } ${item.transmission ? item.transmission : ""}`,
          paymentId: item.id,
          soldPrice: item.soldPrice,
          revisedPrice: item.revisedPrice,
          price: item.remainAmount,
          paymentType: "cash",
          amount: null,
          payDate: moment().format("YYYY-MM-DD"),
          note: null,
          documentId: null,
          reference: null,
        };
        console.log({ dd: this.data });

        this.$bvModal.show("payment-modal");
      },
      hide() {
        this.$bvModal.hide("payment-modal");
      },
      handleOk(bvModal) {
        bvModal.preventDefault();
        if (this.loading) {
          return;
        }

        this.$refs.payment.validate().then((success) => {
          if (success) {
            this.$bvModal
              .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
                title: this.$t("alert.areYouSure"),
                size: "sm",
                buttonSize: "sm",
                okVariant: "success",
                okTitle: this.$t("button.save"),
                cancelTitle: this.$t("button.cancel"),
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  this.submit();
                }
              });
          }
        });
      },
      submit() {
        this.loading = true;
        PaymentHistoryRepository.create(this.data)
          .then((response) => {
            this.hide();
            this.$emit("success");
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              this.$refs.payment.setErrors(error.response?.data?.errors);
            }
          })
          .then(() => {
            this.loading = false;
          });
      },
    },
  };
</script>